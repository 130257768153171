import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "~/components/Button";
import { TextInput } from "~/components/TextInput";
import { Loader2, X } from "lucide-react";
import { useFetcher } from "@remix-run/react";
import { EmojiPicker } from "~/components/EmojiPicker";
import { SettingIcon } from "~/components/SettingIcon";

export const BoardSettings = ({
  board,
  setMenuOpen,
}: {
  board: { _id: string; name: string; emojiId?: string | null | undefined };
  setMenuOpen?: (open: boolean) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const [emojiId, setEmojiId] = useState<string | null>(board.emojiId ?? null);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [open]);

  const fetcher = useFetcher<any>();
  useEffect(() => {
    if (fetcher.state === "submitting") setOpen(false);
  }, [fetcher.state]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild>
          <Button
            variant={"transparent"}
            size="icon"
            className="w-8 h-8 rounded-lg touch-none bg-[#161618]"
            onClick={() => {
              if (setMenuOpen) setMenuOpen(false);
            }}
          >
            <SettingIcon />
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-black/60 fixed inset-0 p-4 flex items-center justify-center z-20 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade">
            <Dialog.Content className="w-full max-w-lg bg-primary-foreground rounded-xl p-5 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade">
              <div className="flex items-center justify-between">
                <Dialog.Title className="text-md font-medium text-secondary">
                  {confirmDelete ? "Delete Board" : "Edit Board"}
                </Dialog.Title>
                <Dialog.Close asChild>
                  <Button
                    variant={"transparent"}
                    className="w-6 h-6 p-0 rounded-full flex items-center justify-center"
                  >
                    <X className="w-4 h-4 text-gray-300" />
                  </Button>
                </Dialog.Close>
              </div>

              {confirmDelete ? (
                <div>
                  <div className="py-5">
                    <div className="text-white">
                      This action is NOT reversible. It will delete all data including columns.
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 pt-2">
                    <Button
                      variant={"destructive"}
                      size={"sm"}
                      onClick={(e) => {
                        e.preventDefault();
                        fetcher.submit(
                          { _action: "board-delete", boardId: board._id },
                          { method: "POST", action: "/boards" }
                        );
                      }}
                    >
                      Confirm Board Deletion
                      {fetcher.state !== "idle" && (
                        <Loader2 className="ml-1 h-4 w-4 animate-spin" />
                      )}
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="py-5">
                    <TextInput
                      type="text"
                      placeholder="Name"
                      className="px-4 py-[10px] w-full rounded-xl mb-2"
                      defaultValue={board.name}
                      ref={ref}
                      name="name"
                    />
                    <EmojiPicker emojiId={emojiId} onEmojiPicked={setEmojiId} />
                  </div>

                  <div className="flex justify-between gap-2 pt-2">
                    <Button
                      variant={"destructive"}
                      size={"sm"}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant={"secondary"}
                      size={"sm"}
                      onClick={(e) => {
                        e.preventDefault();
                        fetcher.submit(
                          {
                            name: ref.current?.value!,
                            emojiId,
                            boardId: board._id,
                            _action: "board-edit",
                          },
                          { action: "/boards", method: "POST" }
                        );
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
