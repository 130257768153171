import { BoardCreate } from "~/routes/boards/components/LeftMenu/components/BoardCreate";
import { cn } from "~/utils/cn";
import { BoardSettings } from "../../BoardSettings";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { Button } from "~/components/Button";
import { GripVertical } from "lucide-react";
import { useRaisedShadow } from "~/hooks/useRaisedShadow";
import { Emoji } from "~/components/Emoji";
import { NewCountBadge } from "~/components/NewCountBadge";
import { LeftMenuUser } from "..";
import { useEffect, useState } from "react";
import { Link, useFetcher } from "@remix-run/react";

export const Boards = ({
  user,
  currentBoardId,
  setMenuOpen,
  expanded,
}: {
  user: LeftMenuUser;
  currentBoardId: string | undefined;
  setMenuOpen?: (open: boolean) => void;
  expanded: boolean;
}) => {
  const [boards, setBoards] = useState(user.boards.sort((a, b) => a.order - b.order));
  useEffect(() => {
    setBoards(user.boards.sort((a, b) => a.order - b.order));
  }, [user.boards]);
  const fetcher = useFetcher<any>();

  return (
    <div className={cn("w-full h-full bg-[#1C1C1F] rounded-2xl", expanded ? "p-4" : "p-2")}>
      <div className="flex items-center justify-between mb-2">
        {expanded && (
          <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#fdfdfd] text-base tracking-[-0.10px] leading-4">
            Boards
          </div>
        )}
        <BoardCreate setMenuOpen={setMenuOpen} expanded={expanded} />
      </div>
      <div className="overflow-y-scroll h-full">
        <Reorder.Group
          axis="y"
          onReorder={(v) => {
            setBoards(v);
            fetcher.submit(
              { _action: "board-order", boardIds: JSON.stringify(v.map((x) => x._id)) },
              { method: "POST" }
            );
          }}
          values={boards}
          className="flex flex-col"
        >
          {boards.map((board) => {
            return (
              <BoardItem
                key={board._id}
                board={board!}
                currentBoardId={currentBoardId}
                setMenuOpen={setMenuOpen}
                expanded={expanded}
              />
            );
          })}
        </Reorder.Group>
      </div>
    </div>
  );
};

const BoardItem: React.FC<{
  board: {
    _id: string;
    name: string;
    emojiId?: string | null | undefined;
    order: number;
    newAlertsCount: number;
  };
  expanded: boolean;
  currentBoardId?: string;
  setMenuOpen?: (open: boolean) => void;
}> = ({ board, expanded, currentBoardId, setMenuOpen }) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Reorder.Item
      value={board}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
    >
      {board._id === currentBoardId ? (
        <div
          className={cn(
            "group/parent relative justify-between flex items-center bg-[#27272B] rounded-lg cursor-default",
            expanded ? "h-[48px] p-2" : "h-[32px] p-2"
          )}
        >
          <div className="flex items-center justify-between gap-2 flex-grow">
            <Emoji emojiId={board.emojiId} />
            {expanded && (
              <div className="w-[70px] text-[#fdfdfd] text-sm font-medium font-['Inter'] leading-none relative text-ellipsis truncate flex-grow">
                {board.name}
              </div>
            )}

            {expanded && (
              <div className="flex gap-1">
                <BoardSettings board={board} setMenuOpen={setMenuOpen} />
                <Button
                  variant={"transparent"}
                  size={"icon"}
                  className={cn(
                    isDragging ? "cursor-grabbing" : "cursor-grab",
                    "w-8 h-8 rounded-lg touch-none bg-[#161618]"
                  )}
                  onPointerDown={(event) => dragControls.start(event)}
                >
                  <GripVertical className="w-4 h-4 text-gray-200" />
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Link
          to={`/boards/${board._id}`}
          key={board._id}
          className={cn(
            "p-2 group/parent relative flex items-center justify-between bg-[#1C1C1F] rounded-lg cursor-pointer",
            expanded ? "h-[48px]" : "h-[32px]"
          )}
          onClick={() => {
            setMenuOpen ? setMenuOpen(false) : "";
          }}
        >
          <div className="flex items-center justify-start gap-2">
            <Emoji emojiId={board.emojiId} />
            {expanded && (
              <div className="w-[125px] text-[#fdfdfd] text-sm font-medium font-['Inter'] leading-none relative text-ellipsis truncate">
                {board.name}
              </div>
            )}
          </div>
          {expanded && <NewCountBadge count={board.newAlertsCount} />}
        </Link>
      )}
    </Reorder.Item>
  );
};
