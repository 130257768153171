import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "~/components/Button";
import { TextInput } from "~/components/TextInput";
import { useFetcher } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import { useNewBoard } from "../../../contexts/NewBoardContext";
import { Plus, X } from "lucide-react";
import { EmojiPicker } from "~/components/EmojiPicker";
import { cn } from "~/utils/cn";

export const BoardCreate = ({
  setMenuOpen,
  expanded,
}: {
  setMenuOpen?: (open: boolean) => void;
  expanded: boolean;
}) => {
  const fetcher = useFetcher<any>();
  const { showCreateBoard, setShowCreateBoard } = useNewBoard();
  const [emojiId, setEmojiId] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showCreateBoard) {
      setEmojiId(null);
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [showCreateBoard]);
  useEffect(() => {
    if (fetcher.state === "submitting") setShowCreateBoard(false);
  }, [fetcher.state]);

  return (
    <>
      <Dialog.Root open={showCreateBoard} onOpenChange={setShowCreateBoard}>
        <Dialog.Trigger asChild>
          <Button
            className={cn(
              expanded ? "py-2 px-2.5" : "p-2",
              "rounded-lg flex justify-center items-center cursor-pointer bg-[#161618] hover:bg-black"
            )}
            variant={"transparent"}
            size={"xs"}
            onClick={() => {
              if (setMenuOpen) setMenuOpen(false);
            }}
          >
            <Plus className="w-4 h-4 text-primary" strokeWidth={3.5} />
            {expanded && (
              <div className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#fdfdfd] text-sm tracking-[-0.08px] leading-4">
                New Board
              </div>
            )}
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-black/60 fixed inset-0 p-4 flex items-center justify-center z-20 will-change-[opacity] data-[state=open]:animate-slideDownAndFade">
            <Dialog.Content className="w-full max-w-lg bg-primary-foreground rounded-xl p-5 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade">
              <fetcher.Form method="POST">
                <div className="flex items-center justify-between">
                  <Dialog.Title className="text-md font-medium text-secondary">
                    Create a Board
                  </Dialog.Title>
                  <Dialog.Close asChild>
                    <Button
                      variant={"transparent"}
                      className="w-6 h-6 p-0 rounded-full flex items-center justify-center"
                    >
                      <X className="w-4 h-4 text-gray-300" />
                    </Button>
                  </Dialog.Close>
                </div>
                <div className="py-5">
                  <TextInput
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="px-4 py-[10px] w-full rounded-xl mb-2"
                    ref={ref}
                  />
                  <EmojiPicker emojiId={emojiId} onEmojiPicked={setEmojiId} />
                </div>

                {emojiId && <input type="hidden" name="emojiId" value={emojiId} />}

                <div className="flex justify-end pt-2">
                  <Button
                    variant={"secondary"}
                    size={"sm"}
                    type="submit"
                    name="_action"
                    value="board-create"
                  >
                    Create
                  </Button>
                </div>
              </fetcher.Form>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
